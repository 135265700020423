import { HeadingSm, HeadingXs } from '/features/buildingBlocks/Heading'
import { animated, useTransition } from 'react-spring'
import { Icon } from '/features/buildingBlocks/Icon'
import { useTranslate } from '/machinery/I18n'
import { BarGraph } from '/features/pageOnly/wavemakers/BarGraph'

import iconTabs from '/images/icons/wavemakers/tabs.raw.svg'
import iconImages from '/images/icons/wavemakers/images.raw.svg'
import iconGlobe from '/images/icons/wavemakers/globe.raw.svg'

import styles from './BarGraphInfographic.css'

const States = {
  TABS: 1,
  IMAGES: 2,
  GLOBE: 3
}

const OFFSET = 5

export function BarGraphInfographic({ state, parentIndexOffset, isVisible, layoutClassName = undefined }) {
  return (
    <section
      data-x='bar-graph-infographic'
      className={cx(styles.component, isVisible && styles.isVisible, layoutClassName)}
    >
      <BarGraphContainer layoutClassName={styles.barGraphLayout} state={state + parentIndexOffset} />
    </section>
  )
}

function BarGraphContainer({ state, layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentBarGraphContainer, layoutClassName)}>
      <HeadingXs h={3} title={__`wavemakers-infographic-subheading`} layoutClassName={styles.subheadingLayout} />
      <Heading layoutClassName={styles.headingLayout} {...{ state }} />
      <Illustration layoutClassName={styles.iconLayout} {...{ state }} />
      <BarGraph layoutClassName={styles.barGraphLayout} {...{ state }} />
      <p className={styles.disclaimer}>
        {__`wavemakers-infographic-disclaimer`}
      </p>
    </div>
  )
}

function Heading({ state, layoutClassName }) {
  const { __ } = useTranslate()

  const transition = useTransition(Math.min(3, state - OFFSET), {
    immediate: () => window.matchMedia('prefers-reduced-motion')?.matches,
    from: { opacity: 0, y: 10 },
    enter: { opacity: 1, y: 0, delay: 100 },
    leave: { opacity: 0, y: -10 },
  })

  return transition((style, item) => (
    <animated.div className={cx(styles.componentHeading, layoutClassName)} {...{ style }}>
      <HeadingSm h={3} title={__`wavemakers-infographic-heading-${Math.max(1, item)}`} />
    </animated.div>
  ))
}

function Illustration({ state, layoutClassName }) {
  const iconsPerState = {
    [States.TABS]: iconTabs,
    [States.IMAGES]: iconImages,
    [States.GLOBE]: iconGlobe,
  }

  const transition = useTransition(iconsPerState[Math.min(3, state - OFFSET)], {
    immediate: () => window.matchMedia('prefers-reduced-motion')?.matches,
    from: { opacity: 0, y: 10 },
    enter: { opacity: 1, y: 0, delay: 100 },
    leave: { opacity: 0, y: -10 },
  })

  return transition((style, icon) => (
    <animated.div className={cx(styles.componentIllustration, layoutClassName)} {...{ style }}>
      <Icon {...{ icon }} />
    </animated.div>
  ))
}
