import { config, animated, useSpring, easings } from 'react-spring'

import dimensions from './dimensions'
import data from './barGraphData'

import cssColors from '/cssGlobal/color.css'

const patternId = 'pattern'
const colors = {
  black: cssColors.colorBlack,
  blue: cssColors.colorBlue500,
  red: cssColors.colorOrange700
}

export function BarGraph({ state = 1, layoutClassName = undefined }) {
  const springs = useBarGraph({ state })

  const spaceX = state !== 3 ? 10 : 20
  const spaceY = 10

  return (
    <Svg {...{ layoutClassName }} >
      <Axes />
      <LeftRuler spacingSpringValue={springs.spacing} {...{ spaceX }} />
      <BottomRuler {...{ spaceY }} />
      <animated.path d={springs.d} fill={`url('#${patternId}')`} />
      <Definitions fill={springs.color} {...{ state, patternId }} />
    </Svg>
  )
}

function Svg({ children, layoutClassName }) {
  return (
    <svg
      viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
      className={layoutClassName}
      {...{ children }}
    />
  )
}

function Axes() {
  const size = dimensions.width - dimensions.offset
  const props = { stroke: colors.blue, strokeWidth: dimensions.lineWidth }

  return (
    <>
      <path d={`M${dimensions.offset} ${size} L${dimensions.offset} 0`} {...props} />
      <path d={`M0 ${size} ${dimensions.width} ${size}`} {...props} />
    </>
  )
}

function LeftRuler({ spacingSpringValue, spaceX }) {
  return Array.from({ length: spaceX * 2.2 }, (_, i) => (
    <animated.path
      key={i}
      d={getPathForIndex(i)}
      strokeWidth={dimensions.lineWidth}
      stroke={colors.blue}
    />
  ))

  function getPathForIndex(i) {
    return spacingSpringValue.to((value) => {
      const thickness = dimensions.lineWidth / 2
      const size = (dimensions.height - thickness) - dimensions.offset
      const x = thickness + size - ((i * size) / spaceX) * value

      return `M0 ${x} L${dimensions.offset} ${x}`
    })
  }
}

function BottomRuler({ spaceY }) {
  return Array.from({ length: spaceY }, (_, i) => (
    <path key={i} d={getPathForIndex(i)} stroke={colors.blue} strokeWidth={dimensions.lineWidth} />
  ))

  function getPathForIndex(i) {
    const size = dimensions.width - dimensions.offset
    const x = dimensions.offset + i * (size / spaceY)

    return `M${x} ${dimensions.width} L${x} ${size}`
  }
}

function Definitions({ state, fill, patternId }) {
  const width = 7
  const height = 10

  return (
    <defs>
      <pattern id={patternId} patternUnits="userSpaceOnUse" {...{ width, height }} >
        <SvgAnimator />
        <SvgBackground {...{ state, fill }} />
        <SvgLine {...{ height }} />
      </pattern>
    </defs>
  )
}

function SvgLine({ height }) {
  return (
    <line
      strokeOpacity={0.1}
      stroke={colors.black}
      strokeWidth={dimensions.lineWidth}
      y2={height}
    />
  )
}

function SvgAnimator() {
  return (
    <animateTransform
      attributeType="xml"
      attributeName="patternTransform"
      repeatCount="indefinite"
      type="translate"
      begin="0s"
      dur="0.25s"
      from="0"
      to="7"
    />
  )
}

function SvgBackground({ state, fill }) {
  return (
    <>
      <rect width={dimensions.width} height={dimensions.height} fill={colors.black} />
      <animated.rect width={dimensions.width} height={dimensions.height} {...{ fill }}>
        <animate
          repeatCount="indefinite"
          attributeName="opacity"
          values={`1; ${state === 3 ? 0.6 : 0.8}; 1;`}
          dur='1.5s'
        />
      </animated.rect>
    </>
  )
}

function useBarGraph({ state }) {
  const coordinates = React.useMemo(() => ({
    1: generatePathD(data[1]),
    2: generatePathD(data[2]),
    3: generatePathD(data[3]),
  }), [])
  const OFFSET = 5

  const [{ d, color }] = useSpring(() => ({
    immediate: () => window.matchMedia('prefers-reduced-motion')?.matches,
    config: key => state === 3 + OFFSET && key === 'd'
      ? { duration: 450, easings: x => x * x }
      : { duration: 350 },
    color: state >= 3 + OFFSET ? colors.red : colors.blue,
    d: coordinates[Math.min(3, state - OFFSET)],
  }), [state])

  const [{ spacing }] = useSpring({
    config: config.molasses,
    spacing: state === 3 + OFFSET ? 0.5 : 1
  }, [state])

  return { d, color, spacing }
}


function generatePathD(points, radius = 5) {
  if (points.length < 2) return ''

  const path = []
  for (let i = 0; i < points.length; i++) {
    const { x, y } = points[i]

    if (i === 0) {
      path.push(`M${x},${y}`)
    }

    if (i !== 0) {
      const { x: prevX, y: prevY } = points[i - 1]
      const next = points[i + 1] ? points[i + 1] : points[i]
      const { x: nextX, y: nextY } = next

      const lengthPrev = Math.sqrt(
        Math.pow(x - prevX, 2) + Math.pow(y - prevY, 2)
      )
      const lengthNext = Math.sqrt(
        Math.pow(nextX - x, 2) + Math.pow(nextY - y, 2)
      )

      const prevOffsetX = (radius * (x - prevX)) / lengthPrev
      const prevOffsetY = (radius * (y - prevY)) / lengthPrev

      const nextOffsetX = (radius * (nextX - x)) / lengthNext
      const nextOffsetY = (radius * (nextY - y)) / lengthNext

      if (next.rounded !== false) {
        path.push(`L${x - prevOffsetX},${y - prevOffsetY}`)
      }

      if (next.rounded === false) {
        path.push(`L${x},${y}`)
      }

      if (next.rounded !== false) {
        path.push(`Q${x},${y} ${x + nextOffsetX},${y + nextOffsetY}`)
      }
    }
  }

  return path.join(' ')
}
