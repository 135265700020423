import { useSpring,  animated } from '@react-spring/web'
import { useScrollProgression, triggers } from '@kaliber/scroll-progression'
import { useOpenVideoPreview } from '/features/pageOnly/wavemakers/videoPreviewHooks'
import { useMediaQuery } from '@kaliber/use-media-query'

import { useTranslate } from '/machinery/I18n'

import { BackgroundVideo } from '/features/buildingBlocks/BackgroundVideo'
import { BarGraphInfographic } from '/features/pageOnly/wavemakers/BarGraphInfographic'
import { Anchor } from '/features/pageOnly/wavemakers/Anchor'
import { Icon } from '/features/buildingBlocks/Icon'

import data from './barGraphData'

import mediaStyles from '/cssGlobal/media.css'
import styles from './WavemakersHero.css'

import poster from '/images/videoPosterWavemakers.jpg'
import glitch1 from '/images/wavemakers/glitch/glitch-1.png'
import glitch2 from '/images/wavemakers/glitch/glitch-2.png'
import glitch3 from '/images/wavemakers/glitch/glitch-3.png'
import doubleChevronIcon from '/images/icons/double-chevron-down.raw.svg'

export function WavemakersHero({ videoSourcesDesktop, videoSourcesMobile, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  const headings = [
    __`wavemakers-hero-heading-1`,
    __`wavemakers-hero-heading-2`,
    __`wavemakers-hero-heading-3`
  ]

  const { ref, states } = useProgression({
    headingCount: headings.length,
    barGraphDataCount: Object.keys(data).length
  })

  const barGraphSliderOffset = headings.length

  const videoSources = isViewportMd ? videoSourcesDesktop : videoSourcesMobile

  return (
    <section data-x='hero' className={cx(styles.component, layoutClassName)} {...{ ref }}>
      <div className={styles.heroWrapper}>
        <div className={styles.heroContainer}>
          <HeadingsContainer
            activeIndex={states.activeIndex}
            layoutClassName={styles.textContainerLayout}
            {...{ headings }}
          />

          <Anchor state='expanded' layoutClassName={styles.anchorLayout} />

          <Glitch
            isActive={states.activeIndex > headings.length - 1}
            layoutClassName={styles.glitchLayout}
          />

          <BarGraphInfographic
            state={states.activeIndex}
            isVisible={states.activeIndex > headings.length - 1}
            parentIndexOffset={barGraphSliderOffset}
            layoutClassName={styles.barGraphLayout}
          />

          <ScrollNudge
            isActive={states.activeIndex < 2}
            layoutClassName={styles.scrollNudgeLayout}
          />

          <ProgressIndicators
            activeIndex={states.activeIndex}
            count={headings.length + Object.keys(data).length}
            layoutClassName={styles.progressIndicatorsLayout}
          />

          {videoSources && (
            <BackgroundVideo
              sources={videoSources}
              layoutClassName={styles.videoLayout}
              {...{ poster }}
            />
          )}
        </div>
      </div>
    </section>
  )
}

function ScrollNudge({ isActive, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentScrollNudge, isActive && styles.isActive, layoutClassName)}>
      <Icon icon={doubleChevronIcon} layoutClassName={styles.iconLayout} />
    </div>
  )
}

function ProgressIndicators({ count, activeIndex, layoutClassName = undefined }) {
  return (
    <ol className={cx(styles.componentProgressIndicators, layoutClassName)}>
      {Array.from({ length: count }, (_, i) => (
        <li
          key={i}
          className={cx(
            styles.indicator,
            activeIndex === i && styles.isActive,
            [0, 1, 2].includes(activeIndex) && styles.onVideo,
            [3, 4, 5].includes(activeIndex) && styles.onWhite
          )}
        />
      ))}
    </ol>
  )
}

function useProgression({ headingCount, barGraphDataCount }) {
  const [hasEnded, setHasEnded] = React.useState(false)
  const [index, setIndex] = React.useState(0)
  const open = useOpenVideoPreview()

  const ref = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.top() },
    end: { element: triggers.bottom(), scrollParent: triggers.bottom() },
    onChange(progression) {
      const count = headingCount + barGraphDataCount
      const currentId = Math.floor(progression * count)

      if (progression === 1) setHasEnded(true)
      if (progression < 1 && hasEnded) setHasEnded(false)

      if (!hasEnded) {
        if (currentId < 2) open('closed')
        if (currentId >= 2) open('expanded')
      }

      setIndex(Math.min(currentId, (headingCount + barGraphDataCount - 1)))
    }
  })

  return {
    ref,
    states: {
      hasEnded,
      activeIndex: index,
    }
  }
}

const glitchSourceArray = [glitch1, glitch2, glitch3]

function Glitch({ isActive, layoutClassName = undefined }) {
  const [{ x, index }] = useGlitchSpring({
    respondToStateChangeOf: isActive,
  })

  return (
    <div className={cx(styles.componentGlitch, layoutClassName)}>
      {glitchSourceArray.map((src, i) => (
        <animated.img
          key={i}
          className={styles.glitchFrame}
          style={{
            background: index.to(x => x === 4 ? 'white' : 'transparent'),
            opacity: index.to(x => Number(x >= i + 1)),
            x
          }}
          {...{ src }}
        />
      ))}
    </div>
  )
}

function useGlitchSpring({ respondToStateChangeOf }) {
  return useSpring(
    {
      immediate: () => window.matchMedia('prefers-reduced-motion')?.matches,
      config: { duration: 85, easing: x => x * x },
      from: { x: 0, index: 0 },
      to: respondToStateChangeOf
        ? [
          { index: 1, x: 0, immediate: true },
          { index: 0, x: 20, immediate: true },
          { index: 1, x: -10, immediate: true },
          { index: 3, x: -10, immediate: true },
          { index: 2, x: 5, immediate: true },
          { index: 2, x: 0, immediate: true },
          { index: 1, x: 20, immediate: true },
          { index: 2, x: -10, immediate: true },
          { index: 3, x: 5, immediate: true },
          { index: 4, x: 0, immediate: true }
        ]
        : [{ index: 0 }]
    },
    [respondToStateChangeOf]
  )
}

function HeadingsContainer({ headings, activeIndex, layoutClassName = undefined }) {
  return (
    <section
      data-x='sticky-heading-container'
      className={cx(styles.componentHeadingsContainer, layoutClassName)}
    >
      {headings.map((x, i) => {
        const Heading = i === 0
          ? HeadingPrimary
          : HeadingSecondary

        return <Heading key={i} text={x} isActive={activeIndex === i} />
      })}
    </section>
  )
}

function HeadingPrimary({ text, isActive }) {
  return (
    <HeadingBase h={1} className={styles.componentHeadingPrimary} {...{ text, isActive }} />
  )
}

function HeadingSecondary({ text, isActive }) {
  return (
    <HeadingBase h={2} className={styles.componentHeadingSecondary} {...{ text, isActive }} />
  )
}

function HeadingBase({ text, h, isActive, className }) {
  const ElementBase = h !== undefined
    ? animated[`h${h}`]
    : animated.div

  const style = useSpring({ config, opacity: Number(isActive), x: 0 })

  return (
    <ElementBase className={cx(styles.componentHeadingBase, className)} {...{ style }}>
      {text}
    </ElementBase>
  )

  function config(key) {
    return key !== 'opacity'
      ? { mass: 0.2, tension: 500, friction: 5 }
      : {}
  }
}
