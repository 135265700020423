import dimensions from './dimensions'

const baseOffset = dimensions?.offset * 2
const base = dimensions?.width - baseOffset

const points1 = withDefaultEdgeCoordinates([
  { x: baseOffset, y: 480 },

  { x: 50, y: 480 },
  { x: 80, y: 460 },

  { x: 250, y: 460 },
  { x: 280, y: 460 },

  { x: 320, y: 460 },
  { x: 350, y: 440 },

  { x: dimensions.width, y: 440 },
])

const points2 = withDefaultEdgeCoordinates([
  { x: baseOffset, y: 420 },

  { x: 50, y: 420 },
  { x: 80, y: 390 },

  { x: 300, y: 390 },
  { x: 330, y: 360 },

  { x: 420, y: 360 },
  { x: 450, y: 340 },

  { x: dimensions?.width, y: 340 },
])

const points3 = withDefaultEdgeCoordinates([
  { x: baseOffset, y: base },

  { x: baseOffset, y: 0 },
  { x: 80, y: 0 },

  { x: 300, y: 0 },
  { x: 330, y: 0 },

  { x: 420, y: 0 },
  { x: 450, y: 0 },

  { x: dimensions?.width, y: 0 },
])

function withDefaultEdgeCoordinates(x) {
  return [
    // Start
    { x: baseOffset, y: base, rounded: false },
    { x: baseOffset, y: base, rounded: false },

    // Custom points
    ...x.map((x, i) => i <= 1 ? ({ ...x, rounded: false }) : x),

    // End
    { x: dimensions?.width, y: base, rounded: false },
    { x: baseOffset, y: base, rounded: false },
  ]
}

const points = {
  1: points1,
  2: points2,
  3: points3,
}

function withAssuredArity(x) {
  const longestArrayItemCount = Object
    .values(x)
    .reduce((result, v) => v.length > result ? v.length : result, 0)

  const entries = Object
    .entries(x)
    .map(([k, v]) => v.length < longestArrayItemCount
      ? correctArity(k, v, longestArrayItemCount)
      : [k, v]
    )

  return Object.fromEntries(entries)
}

function correctArity(key, value, longestArrayItemCount) {
  const missingItemCount = longestArrayItemCount - value.length
  const offset = 2

  for (let i = 0; i < missingItemCount; i++) {
    const index = value.length - offset
    const data = { x: value[index].x, y: value[index].y }
    value.splice(index, 0, data)
  }

  return [key, value]
}

export default withAssuredArity(points)
